@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: 'Clash Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.second-font {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,
li {
  list-style: none;
}

.wrapper {
  padding: 0 10rem;
}
.grr {
  padding: 3rem 10rem;
}
.text {
  font-size: 1rem;
  font-weight: 500;
}
.text2 {
  font-size: 1.1rem;
  font-weight: 400;
}
.dropsize {
  width: 189px;
}
.dropsize2 {
  width: 399px;
}
.hero {
  height: 100%;
  padding-bottom: 4rem;
}
.mdText {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.header1 {
  font-size: 3rem;
  /* line-height: 2.5rem; */
  width: 100%;
  text-align: center;
  margin: 3rem auto 2rem auto;
  font-weight: 500;
}
.header2 {
  font-size: 3rem;
  line-height: 3.5rem;
  width: 100%;
  text-align: left;
  font-weight: 500;
}
.header3 {
  font-size: 3rem;
  color: #fff;
  width: 100%;
  text-align: left;
  margin: 3rem auto 2rem auto;
  font-weight: 500;
}
.contact-form label {
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.2rem 0;
  display: block;
}
.contact-form input,
.contact-form option,
.contact-form select {
  outline: none;
}
/* CareerSteps.css */
.career-steps-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 9rem;
}
.career-steps-container2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
}
.nav-btn {
  display: block;
}
.mobile-navbar a,
.mobile-navbar span {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
  margin: 1.5rem 0 !important;
}

.step,
.step2 {
  max-width: 328px;
}

.font2 {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.circle {
  width: 60px;
  height: 60px;
  background-color: #0094fc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  margin: 0 auto 10px;
}

.circle1 {
  width: 60px;
  height: 60px;
  background-color: #22df72;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  margin: 0 auto 10px;
}

.arrow {
  position: absolute;
  top: 1rem;
  left: 10%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 500px;
}
.arrow2 {
  position: absolute;
  top: -2rem;
  left: 47%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 500px;
}

.arrow img,
.arrow2 img {
  width: 100%;
  height: 55px;
}

.step h3,
.step2 h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 600;
  width: 350px;
  line-height: 28px;
  text-align: center;
}

.step p {
  font-size: 1rem;
  color: #666;
}
.step2 p {
  font-size: 1rem;
  color: #fff;
}
.wrap {
  height: 1100px;
}
.gray-box {
  /* margin: 0 2rem; */
  width: 447px;
}
.boast .upper-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  gap: 3rem;
}
.boast .upper-card .left {
  max-width: 635px;
  /* height: 398px; */
  background-color: #075058;
  background-image: url(/public/asset/images/Circle.png);
  background-repeat: no-repeat;
  background-position: 18rem 18rem;
  padding: 2rem;
  text-align: left;
  border-radius: 16px;
}
.boast .upper-card .left .header2,
.boast .upper-card .right .header2 {
  margin: 1rem;
}
.boast .upper-card .left .ats {
  margin: 1rem;
}
.boast .upper-card .left button,
.boast .upper-card .right button {
  margin: 1rem;
}
.boast .upper-card .right {
  max-width: 635px;
  /* height: 398px; */
  background-color: #0094fc;
  background-image: url(/public/asset/images/spiral.png);
  background-repeat: no-repeat;
  background-position: 24rem 17rem;
  padding: 2rem;
  text-align: left;
  border-radius: 16px;
}
.lower-card {
  position: relative;
  background-color: #f9f9fa;
  width: 100%;
  height: 858px;
  margin: 2rem auto;
  padding: 2rem 4rem;
  border-radius: 15px;
}
.star {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}
.x {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(-50%, -50%);
}
.laptop {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 663px;
  height: auto;
}
.laptop2 {
  position: absolute;
  bottom: 0;
  /* extrem left and centered horizontally */
  left: 5%;
  transform: translateY(10%);
  max-width: 563px;
  height: auto;
  overflow: hidden;
}
.float-image {
  left: 50%;
  top: 10%;
}
.float-image2 {
  left: 30%;
  top: 20%;
}
.background1 {
  background-image: url(/public/asset/images/background1.png);
  background-repeat: no-repeat;
  z-index: 1000;
  background-size: cover;
}
.componet-text1 {
  color: #777777;
  font-size: 1.1rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-transform: capitalize;
}
.componet-text2 {
  color: #22df72;
  font-size: 1.1rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-transform: capitalize;
}
.review-box {
  border: 1px solid #dfe3e8;
}
.join-team {
  background-image: url(/public/asset/images/Vector2.png);
  background-repeat: no-repeat;
  background-position: 55rem 1rem;
}
.price-card {
  border: 1px solid #e3e5ea;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
}
.price-card:hover {
  background-color: #0094fc;
  border: 1px solid #0094fc;
  color: #fff;
}
.termText {
  font-size: 1.1rem;
  font-weight: 400;
  color: #1f2d3b;
}
#privacy-policy h1 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 1rem 0 0.2rem 0;
}
.prive-text1 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #111a24;
  margin: 1rem 0;
}
.prive-text2 {
  font-size: 1rem;
  font-weight: 400;
  color: #111a24;
  margin: 1rem 0;
}
.formHeader {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem auto;
  width: 100%;
  text-align: center;
}
.priceCard {
  display: flex;
  justify-content: center;
}
.bizzPage {
  margin: 10rem 0;
}
.bizz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .wrapper {
    padding: 5rem 1rem 1rem 1rem;
    margin: 0 auto 1rem auto;
  }
  .hero {
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
  .arrow,
  .arrow2 {
    display: none;
  }
  .header1 {
    font-size: 1.5rem;
    margin: 1rem auto;
  }
  .header2 {
    font-size: 1.5rem;
    margin: 1rem auto;
    text-align: center;
    /* line-height: 3rem; */
  }
  .header3 {
    text-align: center;
    font-size: 1.5rem;
    margin: 1rem auto;
  }
  .wrap {
    height: fit-content;
    padding: 1rem 0.5rem 2rem 0.5rem;
  }
  .gray-box {
    margin: 0 auto;
    width: 100%;
  }
  .tab {
    display: none;
  }
  .career-steps-container,
  .career-steps-container2 {
    flex-direction: column;
    margin-top: 1rem;
  }
  .step {
    margin: 1rem auto;
  }
  .step2 {
    margin: 1rem auto;
  }
  .boast .upper-card {
    flex-direction: column;
    color: #fff;
  }
  .boast .upper-card .left {
    margin: 1.5rem auto;
  }
  .boast .upper-card .left .ats {
    margin: 2.5rem 0;
  }
  .boast .upper-card .left .header2,
  .boast .upper-card .right .header2 {
    margin: 1rem 0;
  }
  .boast .upper-card .left button,
  .boast .upper-card .right button {
    margin: 2rem 0;
  }
  .boast .upper-card .right,
  .boast .upper-card .left {
    background-position: 10rem 18rem;
  }
  .lower-card {
    height: 500px;
    padding: 1rem;
  }
  .animate-floating {
    display: none;
  }
  .text-mobile {
    flex-direction: column-reverse;
  }
  .btn-mobile {
    width: 100%;
    margin: 1rem auto;
  }
  .mobile-img {
    width: 100%;
  }
  .mobile-img2 {
    display: none;
  }
  .star {
    display: none;
  }
  .x {
    display: none;
  }
  .laptop {
    width: 90%;
  }
  .laptop2 {
    display: none;
  }
  .priceCard {
    flex-direction: column;
  }
  .bizzPage {
    margin-bottom: 5rem 0;
  }
  .grr {
    padding: 1rem 1rem;
  }
}
